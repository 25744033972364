import { ContractTypes } from '@draftkings/dk-data-layer';
import { formatStadiumResponseData } from '../formatStadiumResponseData';
import { EventPageWidgetConfig } from '@draftkings/event-page-widget-contracts/src/EventPageWidgetConfig';

export const getServerData = (
    serverData: EventPageWidgetConfig['stadiumEventData'],
    eventId: string,
    subCategories: Map<string, ContractTypes.SubCategory>
) => {
    return serverData?.events?.length ? formatStadiumResponseData(serverData, eventId, subCategories) : null;
};
