import { LazyRendering } from '../../contracts/lazyRender/lazyRender';

export const lazyRendering: LazyRendering = {
    /**
     * 40px is the smallest current market height: desktop collapsed market height (34px) + market margin bottom (6px); The number can be adjusted according
     * to performance;
     *
     */
    marketMinHeight: 200,
    /**
     * The rootMargin here is set to be the average between smallest market and longest one, 40px collapsed market, 1580 longest market found; Its value can
     * be adjusted according to performance;
     *
     * https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API - documentation of observerOptions;
     */
    observerOptions: {
        rootMargin: '400px 0px'
    },
    /**
     * The number of markets per lazy render container, value can be adjusted according to performance;
     */
    lazyRenderSubcategoriesChunkSize: 3
};
