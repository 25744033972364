import * as React from 'react';
import { Logos } from '@draftkings/event-page-widget-contracts/src/Logos';
import { ParsedEvent } from '../../contracts/parsers/IParser';
import { TeamLogo } from './TeamLogo';
import { Participant } from '@draftkings/dk-data-layer/lib-esm/contracts';

import './index.scss';

type EventTeamsProps = {
    event: ParsedEvent;
    logosMap: Logos | undefined;
    staticS3Host: string;
    isTeamSwap: boolean;
};

const getTeamNames = (participants: Participant[] | undefined, isTeamSwap: boolean) => {
    const teamName1 = participants?.[0]?.name || '';
    const teamName2 = participants?.[1]?.name || '';
    return isTeamSwap ? [teamName2, teamName1] : [teamName1, teamName2];
};

export function EventTeams({ event, logosMap, staticS3Host, isTeamSwap }: EventTeamsProps) {
    const team1LogoRef = React.useRef<HTMLImageElement>(null);
    const team2LogoRef = React.useRef<HTMLImageElement>(null);

    const [error, setError] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);
    const logosForLeague = logosMap ? logosMap[event.leagueId] : undefined;
    const [teamName1, teamName2] = getTeamNames(event.participants, isTeamSwap);
    const team1LogoUrl = logosForLeague?.Teams?.[teamName1];
    const team2LogoUrl = logosForLeague?.Teams?.[teamName2];
    const middleTerm = logosForLeague?.MiddleTerm?.toUpperCase() || '@';
    const hasLogos = teamName1 && team1LogoUrl && teamName2 && team2LogoUrl;
    const isOutright = event.eventParticipantType === 'MultiTeam' || event.eventParticipantType === 'MultiPlayer';

    const onLoad = () => {
        if (team1LogoRef?.current?.complete && team2LogoRef?.current?.complete) {
            setLoaded(true);
        }
    };

    const onError = () => setError(true);

    React.useEffect(() => {
        onLoad();

        if (
            (team1LogoRef?.current?.complete && !team1LogoRef?.current?.naturalHeight) ||
            (team2LogoRef?.current?.complete && !team2LogoRef?.current?.naturalHeight)
        ) {
            setError(true);
        }
    }, [event, logosMap]);

    return !isOutright && hasLogos && !error ? (
        <div className="ep-event-teams__title" data-testid="ep-event-teams-title">
            {teamName1}
            <TeamLogo
                ref={team1LogoRef}
                logoUrl={team1LogoUrl}
                teamName={teamName1}
                staticS3Host={staticS3Host}
                loaded={loaded}
                onLoad={onLoad}
                onError={onError}
            />
            <div className="ep-event-teams__divider">
                <div className="ep-event-teams__divider-box">{middleTerm}</div>
            </div>
            <TeamLogo
                ref={team2LogoRef}
                logoUrl={team2LogoUrl}
                teamName={teamName2}
                staticS3Host={staticS3Host}
                loaded={loaded}
                onLoad={onLoad}
                onError={onError}
            />
            {teamName2}
        </div>
    ) : (
        <>{event.name}</>
    );
}
