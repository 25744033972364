import { useContext } from 'react';
import { PlayerPageContext } from './PlayerPageContext';

export const usePlayerPageProps = () => {
    const context = useContext(PlayerPageContext);
    if (context === undefined) {
        throw new Error('usePlayerPageProps must be used within a PlayerPageContextProvider');
    }
    return context;
};
