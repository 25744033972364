import { ContractTypes } from '@draftkings/dk-data-layer';
import { IDataIndex, Localization, SelectionHandlerIDs } from '@draftkings/component-builder/src/contracts';
import {
    MarketBuilderConfig,
    MarketWithSelections,
    marketCellsBuilder
} from '@draftkings/component-builder/src/builders/marketCellsBuilder';
import { OddsStyles } from '@draftkings/event-page-widget-contracts/src/OddsStyles';
import { parseScoreboards } from '@draftkings/scoreboards';
import { EventPageWidgetConfig } from '@draftkings/event-page-widget-contracts/src/EventPageWidgetConfig';
import { LogosMap } from '@draftkings/event-page-widget-contracts/src/Logos';
import { ParsedEvent } from '../../../contracts/parsers/IParser';
import { compareByCategoryThenSubcategory, getDefaultValue } from '../../../helpers';
import { FeatureFlags } from '@draftkings/event-page-widget-contracts/src/FeatureFlags';

export type ParseEventArgs = {
    event: ContractTypes.SportEvent;
    marketsWithSelectionsBySubId: Record<string, MarketWithSelections[]>;
    isTeamSwap: boolean;
    categoriesSortOrderMap: Map<string, number>;
    localization: Localization;
    loadingSelectionIndex: IDataIndex<string, boolean>;
    selectedSelectionIndex: IDataIndex<string, boolean>;
    oddsStyle: OddsStyles;
    onSelectionClick: (selectionIds: SelectionHandlerIDs) => void;
    logosMap: LogosMap;
    leagueName: string;
    staticS3Host: string;
    collapsedSubcategoryIds: Set<string>;
    logError: EventPageWidgetConfig['logError'];
    featureFlags: FeatureFlags;
};

export const parseEvent = ({
    event,
    marketsWithSelectionsBySubId,
    isTeamSwap,
    localization,
    loadingSelectionIndex,
    selectedSelectionIndex,
    oddsStyle,
    onSelectionClick,
    logosMap,
    leagueName,
    staticS3Host,
    collapsedSubcategoryIds,
    logError,
    categoriesSortOrderMap,
    featureFlags
}: ParseEventArgs): ParsedEvent => {
    return {
        ...event,
        leagueName,
        scoreboardData: parseScoreboards({
            event,
            logosMap,
            staticS3Host,
            isTeamSwap
        }),
        subcategories: getDefaultValue(
            event.subcategories?.slice().map((subcategory) => {
                return {
                    ...subcategory,
                    subcategorySortingKeys: {
                        categorySortOrder: getDefaultValue(
                            categoriesSortOrderMap.get(subcategory.categoryId),
                            Number.MAX_SAFE_INTEGER
                        ),
                        subcategorySortOrder: subcategory.sortOrder
                    },
                    isExpanded: !collapsedSubcategoryIds.has(subcategory.id),
                    marketsCells: marketCellsBuilder({
                        eventId: event.id,
                        markets: marketsWithSelectionsBySubId[subcategory.id] || [],
                        onSelectionClick,
                        categoryId: subcategory.categoryId,
                        subCategoryId: subcategory.id,
                        componentId: subcategory.componentId,
                        localization,
                        loadingSelectionIndex,
                        selectedSelectionIndex,
                        oddsStyle,
                        isTeamSwap,
                        participants: getDefaultValue(event.participants?.slice(), []),
                        logError: logError as MarketBuilderConfig['logError'],
                        featureFlags,
                        consumerPageName: 'EventPage'
                    })
                };
            }),
            []
        )
            .sort(compareByCategoryThenSubcategory)
            .filter((m) => m.marketsCells.length > 0)
    };
};
