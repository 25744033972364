import { SBMessageBus } from '@draftkings/event-page-widget-contracts/src/MessageBus';
import { ProductConfig } from '@draftkings/event-page-widget-contracts/src/ProductConfig';

type DkConfig = {
    enableGooglePlayStore: boolean;
    sportsbookAppStoreLink: string;
    sportsbookGooglePlayStoreLink: string;
};

declare global {
    interface Window {
        __isNativeApp: boolean;
        __isLoggedIn: boolean;
        dkConfig: DkConfig;
        singularSdk?: {
            buildWebToAppLink?: (link: string) => string;
        };
        addOutcome?: (outcomeId: string) => void;
        removeOutcome?: (outcomeId: string) => void;
    }
}

/* c8 ignore start */
const isAndroid = (userAgent: string | null = null) => {
    if (typeof navigator !== 'undefined' && navigator.userAgent) {
        return navigator.userAgent.indexOf('Android') !== -1;
    } else if (userAgent) {
        return userAgent.indexOf('Android') !== -1;
    }

    return false;
};

const isIOS = (userAgent: string | null = null) => {
    if (typeof navigator !== 'undefined' && navigator.userAgent) {
        return /iPhone|iPad|iPod/i.test(navigator.userAgent);
    } else if (userAgent) {
        return /iPhone|iPad|iPod/i.test(userAgent);
    }

    return false;
};

export const isMobile = (userAgent: string | null = null) => isIOS(userAgent) || isAndroid(userAgent);

export const buildWebToAppLink = (link: string): string => {
    if (typeof window !== 'undefined' && window?.singularSdk?.buildWebToAppLink) {
        return window.singularSdk.buildWebToAppLink(link);
    }
    return link;
};

export const isGooglePlayStoreEnabled = (dkConfig: DkConfig, productConfig: ProductConfig) =>
    dkConfig.enableGooglePlayStore && productConfig.canDownloadAppFromGooglePlayStore;

export const getAppDownloadLink = (productConfig: ProductConfig, userAgent: string | null = null) => {
    let downloadLink: string | null = null;
    const dkConfig = window.dkConfig;

    if (isIOS(userAgent)) {
        downloadLink = buildWebToAppLink(dkConfig.sportsbookAppStoreLink);
    } else if (isAndroid(userAgent)) {
        if (isGooglePlayStoreEnabled(dkConfig, productConfig)) {
            downloadLink = buildWebToAppLink(dkConfig.sportsbookGooglePlayStoreLink);
        } else {
            downloadLink = '/sportsbook-android-app';
        }
    } else {
        downloadLink = '/sportsbook-app';
    }
    return downloadLink;
};

export const isBettableWebview = () => {
    return typeof window !== 'undefined' && window.__isNativeApp && window.addOutcome && window.removeOutcome;
};

export function onMobileToggleSelection(messageBus: SBMessageBus, productConfig: ProductConfig, userKey: string) {
    if (window.__isLoggedIn) {
        messageBus.emit('popup_modal', {
            type: 'OPEN_DOWNLOAD_APP_MODAL',
            payload: { downloadLink: getAppDownloadLink(productConfig), userKey }
        });
    } else {
        window.location.assign('/login?returnUrlPath=/sportsbook-app');
    }
}
/* c8 ignore stop */
