import { Timer } from '../../EventTimer';

export const formatStartingTime = (timer: Timer) => {
    const hours = padZeros(timer.hours);
    const minutes = padZeros(timer.minutes);
    const seconds = padZeros(timer.seconds);
    let startingTime = '';

    if (timer.hours) {
        startingTime += `${hours}:`;
    }
    if (timer.minutes || timer.hours) {
        startingTime += `${minutes}:`;
    }
    if (timer.seconds || timer.minutes) {
        startingTime += `${seconds}`;
    } else {
        startingTime = `${hours}:${minutes}:${seconds}`;
    }

    return startingTime;
};

const padZeros = (value: number | string) => {
    const numericValue = Number(value);
    return numericValue < 10 && numericValue >= 0 ? `0${numericValue}` : String(value);
};
