import React from 'react';
import { SubCategoryMapping } from '@draftkings/component-builder/src/components/SubCategoryMapping';
import { SubCategoryLazyRender } from '@draftkings/component-builder/src/components/SubCategoryLazyRender';
import { ProductConfig } from '@draftkings/event-page-widget-contracts/src/ProductConfig';
import { TrackEventFunction } from '@draftkings/event-page-widget-contracts/src/Tracking';
import { usePlayerPageProps } from '../../contexts/PlayerPageContext/usePlayerPageProps';
import { ChunkedSubCategoriesProps } from '../../contracts/state';
import { lazyRendering } from '../../helpers';

import './index.scss';

type MarketboardProps = {
    chunkedSubCategories: ChunkedSubCategoriesProps[];
    productConfig: ProductConfig;
    toggleIsExpanded: (subcategoryId: string) => void;
    trackEvent: TrackEventFunction;
    consumerVersion: string;
};

export const Marketboard = React.memo(
    ({
        chunkedSubCategories,
        children,
        toggleIsExpanded,
        productConfig,
        trackEvent,
        consumerVersion
    }: React.PropsWithChildren<MarketboardProps>) => {
        const marketboardRef = React.useRef<HTMLDivElement>(null);
        const { playerPageConfig, getPlayerLink, onPlayerLinkClick } = usePlayerPageProps();

        return (
            <div ref={marketboardRef} className="marketboard-layout">
                <div className="marketboard-layout__content">
                    {children}
                    <div className="marketboard-layout__content__main">
                        {chunkedSubCategories.map((subcategories) => {
                            const totalMarketsLength = subcategories.chunkedSubCategories.reduce(
                                (total, subcat) => total + subcat.marketsCells.length,
                                0
                            );
                            return (
                                <SubCategoryLazyRender
                                    key={subcategories.id}
                                    marketsLength={totalMarketsLength}
                                    {...lazyRendering}
                                >
                                    {subcategories.chunkedSubCategories.map((subcategory, i) => {
                                        return (
                                            <SubCategoryMapping
                                                key={`subcategory-${subcategory.id}-${i}`}
                                                trigger={{
                                                    data: {
                                                        title: subcategory.name
                                                    }
                                                }}
                                                marketData={{
                                                    consumerVersion: consumerVersion,
                                                    widgetVersion: APP_VERSION,
                                                    marketsCells: subcategory.marketsCells,
                                                    productConfig: productConfig,
                                                    playerPageConfig: playerPageConfig,
                                                    trackEvent: trackEvent,
                                                    getPlayerLink: getPlayerLink,
                                                    onPlayerLinkClick: onPlayerLinkClick,
                                                    marketboardRef
                                                }}
                                                isExpanded={subcategory.isExpanded}
                                                contentId={subcategory.id}
                                                animationConfig={{
                                                    duration: 300,
                                                    ease: 'ease-in-out'
                                                }}
                                                onToggleExpandClick={() => {
                                                    toggleIsExpanded(subcategory.id);
                                                }}
                                            />
                                        );
                                    })}
                                </SubCategoryLazyRender>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
);
