export const delay = (delay: number = 0, fn: () => number | undefined) => {
    const timerId = setTimeout(() => {
        fn();
    }, delay);

    const cancelDelay = (): number | undefined => {
        clearTimeout(timerId);
        return fn();
    };

    return cancelDelay;
};
