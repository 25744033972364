import * as React from 'react';
import './index.scss';

type EventPageErrorProps = {
    title: string;
    text: string;
    buttonText: string;
    onClick: () => void;
};

export const EventPageError: React.FC<EventPageErrorProps> = React.memo(function EventPageError(
    props: EventPageErrorProps
) {
    const { title, text, buttonText, onClick } = props;

    return (
        <div className="event-page-error" data-testid="event-page-error">
            <p className="event-page-error__heading">{title}</p>
            <p className="event-page-error__text">{text}</p>
            <button type="button" className="event-page-error__button" onClick={onClick}>
                {buttonText}
            </button>
        </div>
    );
});
