export const POPULAR_TAB = 'popular';
export const POPULAR_TAB_NAME = 'Popular';

export const QUICK_SGP_CATEGORY = 'quick sgp';
export const QUICK_SGP_CATEGORY_NAME = 'Quick Sgp';

export const ODDS_CATEGORY = 'odds';
export const ODDS_CATEGORY_NAME = 'Odds';

export const STATS_CATEGORY = 'stats';
export const STATS_CATEGORY_NAME = 'Stats';

export const GOLF_SPORT_ID = '12';

export const EVENT_STATUS_ERROR_CODES = {
    EVENT_NOT_FOUND: 'STDM-404-000'
};

export const TRACKED_UPDATE_ENTITIES = {
    ADD: {
        EVENT: 'ADD_EVENT',
        MARKET: 'ADD_MARKET',
        SELECTION: 'ADD_SELECTION'
    },
    CHANGE: {
        EVENT: 'CHANGE_EVENT',
        MARKET: 'CHANGE_MARKET',
        SELECTION: 'CHANGE_SELECTIONS'
    },
    REMOVE: {
        EVENT: 'REMOVE_EVENT',
        MARKET: 'REMOVE_MARKET',
        SELECTION: 'REMOVE_SELECTION'
    }
};
