type IsCategorySelectedParams = {
    index: number;
    hasPopularTab: boolean;
    categoryId: string;
    selectedCategoryId: string | undefined;
};

export function isCategorySelected({ index, hasPopularTab, categoryId, selectedCategoryId }: IsCategorySelectedParams) {
    if (categoryId === selectedCategoryId) {
        return true;
    }
    return hasPopularTab ? false : index === 0;
}
