import React from 'react';

import './index.scss';

interface TeamLogoProps {
    logoUrl: string;
    teamName: string;
    staticS3Host: string;
    onLoad: () => void;
    onError: () => void;
    loaded: boolean;
}

export const TeamLogo = React.forwardRef(function TeamLogoComponent(
    { logoUrl, teamName, staticS3Host, onLoad, onError, loaded }: TeamLogoProps,
    ref: React.ForwardedRef<HTMLImageElement>
) {
    if (!logoUrl) {
        return null;
    }

    return (
        <div className={loaded ? '' : 'ep-event-name__team-logo-skeleton'}>
            <img
                className="ep-event-name__team-logo"
                data-testid="ep-event-team-logo"
                ref={ref}
                alt={`${teamName}-logo`}
                src={`${staticS3Host}${logoUrl}`}
                onLoad={onLoad}
                onError={onError}
                style={{ visibility: loaded ? 'visible' : 'hidden' }}
            />
        </div>
    );
});
