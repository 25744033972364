import { ContractTypes } from '@draftkings/dk-data-layer';

export const formatStadiumResponseData = (
    data: ContractTypes.StadiumResponse,
    eventId: string,
    subCategories: Map<string, ContractTypes.SubCategory>
) => {
    const key = data.events.find((el) => el.subscriptionKey)?.subscriptionKey || `event-${eventId}`;

    return {
        sports: new Map<string, ContractTypes.Sport>(data.sports.map((sport) => [sport.id, sport])),
        events: new Map<string, ContractTypes.SportEvent>(
            data.events.map((event) => [event.id, { ...event, subcategories: [...subCategories.values()] }])
        ),
        markets: new Map<string, ContractTypes.Market>(data.markets?.map((market) => [market.id, market])),
        selections: new Map<string, ContractTypes.Selection>(
            data.selections?.map((selection) => [selection.id, selection])
        ),
        leagues: new Map<string, ContractTypes.League>(data.leagues.map((league) => [league.id, league])),
        subscriptionPartials: {
            ...(data.subscriptionPartials[key] || {
                entity: 'events',
                query: `$filter=id eq '${eventId}'`,
                includeMarkets: "$filter=tags/all(t: t ne 'SportcastBetBuilder')"
            })
        }
    };
};
