import { XClientHeaders } from '@draftkings/event-page-widget-contracts/src/Tracking';

export const getWebHeaders = (consumerVersion: string): XClientHeaders => ({
    'X-Client-Name': 'web',
    'X-Client-Version': consumerVersion,
    'X-Client-Feature': 'event-page',
    'X-Client-Page': 'Event',
    'X-Client-Widget-Name': 'EventPageWidget',
    'X-Client-Widget-Version': APP_VERSION
});
