import { SubcategorySortingKeys } from '../../contracts/parsers/IParser';

type SortableEntity = {
    subcategorySortingKeys: SubcategorySortingKeys;
};

export function compareByCategoryThenSubcategory(a: SortableEntity, b: SortableEntity): number {
    const { categorySortOrder: aFirst, subcategorySortOrder: aSecond } = a.subcategorySortingKeys;
    const { categorySortOrder: bFirst, subcategorySortOrder: bSecond } = b.subcategorySortingKeys;

    if (aFirst === bFirst) {
        return aSecond - bSecond;
    }

    return aFirst - bFirst;
}
