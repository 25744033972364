import * as React from 'react';
import { formatStartingTime } from './helpers';

const SECONDS_IN_DAY = 86400;
const SECONDS_IN_HOUR = 3600;
const SECONDS_IN_MINUTE = 60;
const MILISECONDS_IN_SECOND = 1000;

const isValidDate = (date: Date) => !isNaN(date.getTime());

const hasDatePassed = (date: Date) => {
    const now = new Date();
    return date.getTime() < now.getTime();
};

const calculateTimeRemaining = (startDate: Date) => {
    const now = new Date();
    const remainingSeconds = Math.floor((startDate.getTime() - now.getTime()) / MILISECONDS_IN_SECOND);

    const days = Math.floor(remainingSeconds / SECONDS_IN_DAY);
    const hours = Math.floor((remainingSeconds % SECONDS_IN_DAY) / SECONDS_IN_HOUR);
    const minutes = Math.floor((remainingSeconds % SECONDS_IN_HOUR) / SECONDS_IN_MINUTE);
    const seconds = remainingSeconds % SECONDS_IN_MINUTE;

    return { days, hours, minutes, seconds };
};

const daysDiffInWords = (startDate: Date) => {
    const now = new Date();
    const diffInSeconds = Math.floor(startDate.getTime() - now.getTime()) / MILISECONDS_IN_SECOND;
    const diffInDays = Math.floor(diffInSeconds / SECONDS_IN_DAY);

    return `${diffInDays} ${diffInDays > 1 ? 'days' : 'day'}`;
};

export type Timer = {
    days: string | number;
    hours: string | number;
    minutes: string | number;
    seconds: string | number;
};

const defaultTimer = {
    days: '-',
    hours: '-',
    minutes: '-',
    seconds: '-'
};

type EventTimerProps = {
    eventStartDate: string;
};

export const EventTimer: React.FC<EventTimerProps> = ({ eventStartDate }) => {
    const [timer, setTimer] = React.useState<Timer>(defaultTimer);
    const startDate = React.useMemo(() => new Date(eventStartDate), [eventStartDate]);

    const updateTimer = React.useCallback(() => {
        const remaining = calculateTimeRemaining(startDate);
        setTimer(remaining);
    }, [startDate]);

    React.useEffect(() => {
        if (!isValidDate(startDate) || hasDatePassed(startDate)) {
            return;
        }

        updateTimer();

        const intervalId = setInterval(() => {
            updateTimer();
        }, MILISECONDS_IN_SECOND);

        return () => clearInterval(intervalId);
    }, [startDate, updateTimer]);

    const getStartingTime = () => {
        if (hasDatePassed(startDate)) {
            return '-:-:-';
        }

        if (Number(timer.days) > 0) {
            return daysDiffInWords(startDate);
        }

        return formatStartingTime(timer);
    };

    return <div>Starts in {getStartingTime()}</div>;
};
